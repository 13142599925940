<template>
<!--  v-show="!isSkeletonLoading.commentary" -->
 <div>
    <!-- AS-712 : && !isShowLiveTrackerUrl -->
   <div class="no-data w-100" v-show="!isShowCommentary">{{$t('NO_FEED')}}</div>

    <div class="display-flex match-detail-tracker__feed-commentary__wrapper" >
        <!-- <div class="mb-1rem match-detail__tracker-video" v-if="isShowLiveTrackerUrl">
            <div class="match-detail__tracker-header">
                {{$t('TRACKER')}}
            </div>
            <iframe  width="100%"  frameBorder="0" class="iframe" :src="matchDetailLiveTrackerUrl"></iframe>
        </div> -->
        <div class="match-detail__tracker-commentary" v-if="isShowCommentary">
            <div class="match-detail__tracker-header">
                {{$t('FEED')}}
            </div>
            <div v-if="!isMobile">
                <SessionTabs class="shorter-session-tabs-container" :tabs="matchSessionTabs" @changeTab="switchContent"></SessionTabs>
                <perfect-scrollbar>
                    <div class="match-detail__tracker-body">
                        <div v-for="(o, i) in matchDetailCommentary" :key="i" :id="o.quarter">
                            <template v-if="o.selected">
                                <div v-for="(event, j) in o.quarterList" :key="j" class="match-detail__tracker-commentary__card" :class="`color-${event.color}`">
                                    <div class="match-detail__tracker-commentary-left">{{event.minute}}'</div>
                                    <img class="img-contain icon-team mr-05rem" :src="`${s3ImgUrl}/${teamsInfo.homeLogo}`" v-if="event.home && teamsInfo">
                                    <img class="img-contain icon-team mr-05rem" :src="`${s3ImgUrl}/${teamsInfo.awayLogo}`" v-else-if="!event.home && teamsInfo">
                                    <div class="match-detail__tracker-commentary__event-and-scores">
                                        <div>{{event.content}}</div>
                                        <div class="match-detail__tracker-commentary__score-wrapper">{{event.score}}</div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div v-else>
                <div class="match-detail__tracker-body">
                    <SessionTabs :tabs="matchSessionTabs" @changeTab="switchContent"></SessionTabs>
                    <div class="match-detail__tracker-body">
                        <div v-for="(o, i) in matchDetailCommentary" :key="i" :id="o.quarter">
                            <template v-if="o.selected">
                                <div v-for="(event, j) in o.quarterList" :key="j" class="match-detail__tracker-commentary__card" :class="`color-${event.color}`">
                                    <div class="match-detail__tracker-commentary-left">{{event.minute}}'</div>
                                    <img class="img-contain icon-team mr-05rem" :src="`${s3ImgUrl}/${teamsInfo.homeLogo}`" v-if="event.home && teamsInfo">
                                    <img class="img-contain icon-team mr-05rem" :src="`${s3ImgUrl}/${teamsInfo.awayLogo}`" v-else-if="!event.home && teamsInfo">
                                    <div class="match-detail__tracker-commentary__event-and-scores">
                                        <div>{{event.content}}</div>
                                        <div class="match-detail__tracker-commentary__score-wrapper">{{event.score}}</div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

    <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.commentary"></GeneralContentSkeleton> -->
</template>

<script>
import {mapGetters,mapMutations,mapActions} from 'vuex'
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';
import SessionTabs from '@/components/matchDetail/MatchSessionTabs.vue';
import config from '@/js/config.js'

export default {
    components: {
        GeneralContentSkeleton,
        SessionTabs,
    },
    watch: {
        manualRefreshMatchDetail: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal) this.getMatchDetailCommentaryDataInterval();
            }
        },
        matchDetailScore: {
            handler(newVal, oldVal) {
                if (newVal) 
                    this.teamsInfo = newVal;
            },
        },
    },
    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            isShowLiveTrackerUrl:false,
            isShowCommentary:false,
            isShowNoData:false,
            matchDetailLiveTrackerUrl:'',
            matchDetailCommentary:[],
            matchSessionTabs: [],
            // [
            //     {
            //         name: "Q1",
            //         selected: false,
            //         disabled: true,
            //     },
            //     {
            //         name: "Q2",
            //         selected: false,
            //         disabled: true,
            //     },
            //     {
            //         name: "Q3",
            //         selected: false,
            //         disabled: true,
            //     },
            //     {
            //         name: "Q4",
            //         selected: false,
            //         disabled: true,
            //     },
            //     // {
            //     //     name: "OT1",
            //     //     selected: false,
            //     //     disabled: true,
            //     // },
            //     // {
            //     //     name: "OT2",
            //     //     selected: false,
            //     //     disabled: true,
            //     // },
            //     // {
            //     //     name: "OT3",
            //     //     selected: false,
            //     //     disabled: true,
            //     // },
            // ],
            matchId:'',
            userViewingEventList: {
                bool: false,
                index: null,
            },
            teamsInfo: null,
            intervalCommentary: null,
            isSkeletonLoading: {
                // tracker: false,
                commentary: false,
            },
        }
    },
    created() {
          this.matchId = this.$route.params.matchId;
    },
      beforeUnmount() {
        clearInterval(this.intervalCommentary)
        this.teamsInfo = null;
     },
    computed: {
        ...mapGetters([
            'isStopTimmer',
            'isMobile',
            'manualRefreshMatchDetail',
            "matchDetailScore",
        ]),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations([
            "MANUAL_REFRESH_MATCH_DETAIL",
        ]),
        ...mapActions([
            "getMatchDetailLiveTrackerUrl",
            "getBasketballMatchDetailCommentary"
        ]),

        init() {
            // this.getMatchDetailLiveTrackerUrlData();
            this.getMatchDetailCommentaryDataInterval();
            this.teamsInfo = this.matchDetailScore;
        },
     
        // async getMatchDetailLiveTrackerUrlData(){
        //     if (this.manualRefreshMatchDetail) {
        //         this.isSkeletonLoading.tracker = true;
        //     }
        //     const result = await this.getMatchDetailLiveTrackerUrl({"matchId" : this.matchId})
        //     if (this.manualRefreshMatchDetail) {
        //         this.isSkeletonLoading.tracker = false;
        //         this.verifyIfAllAPICallsDone();
        //     }
        //     //result.result.url ="https://www.g-video.net/4002886.mp4"
            
        //     if (result.result === null){
        //         this.isShowLiveTrackerUrl = false;
        //         this.isShowNoData= true;
        //     } else {
        //         this.isShowNoData= false;
        //         this.isShowLiveTrackerUrl = true;
        //         this.matchDetailLiveTrackerUrl = result.result.url;  
        //     }
        // },  

        async getMatchDetailCommentaryDataInterval(){

            this.getMatchDetailCommentaryData();

            this.intervalCommentary = setInterval(() => {
                    if (this.isStopTimmer) {
                        clearInterval(this.intervalCommentary)
                    } else {
                        this.getMatchDetailCommentaryData();
                    }
                 
            }, 2000);
        },
         async getMatchDetailCommentaryData(){
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.commentary = true;
            }
            const result = await this.getBasketballMatchDetailCommentary({"matchId" : this.matchId})
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.commentary = false;
                this.verifyIfAllAPICallsDone();
            }

            if (result.result.length === 0) {
                this.isShowCommentary = false;
                this.isShowNoData= true;
                if (this.isStopTimmer) {
                    clearInterval(this.intervalCommentary)
                }
            } else {
                this.isShowNoData= false;
                this.isShowCommentary = true;
                this.matchSessionTabs = [];
                let theResult = result.result;
                // [{quarter: "Q1", quarterList: []}, {quarter: "Q2", quarterList: []}, {quarter: "OT", quarterList: []}]
                for (let i = 0, n = theResult.length; i < n; i++) {
                    let toCompare;
                    if (!this.userViewingEventList.bool) {
                        theResult[i].selected = i === n - 1 ? true : false;
                        toCompare = n - 1;
                    } else {
                        theResult[i].selected = i === this.userViewingEventList.index ? true : false;
                        toCompare = this.userViewingEventList.index;
                    }

                    this.matchSessionTabs.push({
                        name: theResult[i].quarter,
                        selected: i === toCompare ? true : false,
                        disabled: false,
                    });
                }
                
                // if (theResult.length < 4) {
                //     for (let i = 0, n = this.matchSessionTabs.length; i < n; i++) {
                //         if (i === theResult.length - 1) {
                //             this.matchSessionTabs[i].selected = true;
                //             this.matchSessionTabs[i].disabled = false;
                //         } else {
                //             this.matchSessionTabs[i].selected = false;
                //             this.matchSessionTabs[i].disabled = false;
                //         }

                //         if (typeof theResult[i] === 'undefined') {
                //             this.matchSessionTabs[i].selected = false;
                //             this.matchSessionTabs[i].disabled = true;
                //         }
                //     }
                // }
                // if (theResult.length >= 4) {
                //     this.matchSessionTabs = [];
                //     for (let i  = 0, n = theResult.length; i < n; i++) {
                //         this.matchSessionTabs.push({
                //             name: theResult[i].quarter,
                //             selected: i === n - 1 ? true : false,
                //             disabled: false,
                //         });
                //     }
                // }
                
                this.matchDetailCommentary = [...theResult];
            }
        },
        switchContent(selectedIndex, disabled) {
            this.userViewingEventList.bool = true;
            this.userViewingEventList.index = selectedIndex;
            if (!disabled) {
                for (let i = 0, n = this.matchSessionTabs.length; i < n; i++) {
                    this.matchSessionTabs[i].selected = (i === selectedIndex) ? true : false;
                }
                for (let i = 0, n = this.matchDetailCommentary.length; i < n; i++) {
                    this.matchDetailCommentary[i].selected = selectedIndex === i ? true : false;
                }
            }
        },
        // !this.isSkeletonLoading.tracker && 
        verifyIfAllAPICallsDone() {
            if (!this.isSkeletonLoading.commentary) {
                this.MANUAL_REFRESH_MATCH_DETAIL();
            }
        }
    },
}
</script>

<style>
.match-detail-tracker__feed-commentary__wrapper{
        flex-direction: column;
    }
/* .match-detail__tracker-video{
    width: 100%;
    height: 50vw
} */
.match-detail__tracker-header{
    padding: 0 1rem 1rem 1rem;
    /* margin: 0 1rem; */
    text-align: left;
    color: var(--color-grey-light);
    font-size: 0.8rem;
}
/* .match-detail__tracker-video .iframe{
        height:100%
    } */
.match-detail__tracker-body{
    margin: 0 1rem;
}
.match-detail__tracker-commentary__card{
    display: flex;
    align-items: center;
    border-bottom:1px solid var(--color-grey-20);
    color:var(--color-grey-light);
    padding: .5rem 0;
    text-align: left;
    font-size:.7rem 
}
.match-detail__tracker-commentary__event-and-scores {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.match-detail__tracker-commentary__score-wrapper {
    min-width: 3rem;
    width: 3rem;
    margin-left: 1rem;
    text-align: center;
}
.match-detail__tracker-commentary__card.text-important{
    color:#ED9C5A
}
/* priority is goal */
.match-detail__tracker-commentary__card.text-goal{ 
    color:#5CD66C
}
.match-detail__tracker-commentary-left{
    min-width: 2.5rem;
}
.shorter-session-tabs-container {
    width: 60%;
    margin: 0 20%;
}
.icon-team {
    width: 1rem;
    height: 1rem;
}
/* green - scored shots */
.color-1 {
    color: var(--color-ok);
}
/* red - missed shots */
.color-2 {
    color: var(--color-error);
}
/* orange - fouls */
.color-3 {
    color: var(--color-orange);
}
@media (min-width: 1200px){
    /* .match-detail__tracker-video{ */
        /* width: 70%; */
        /* flex:3;
        height:100%;
        margin-right:5rem
    } */
    
    .match-detail__tracker-commentary{
        /* width: 30%; */
        flex:1.5
    }
    .match-detail-tracker__feed-commentary__wrapper{
        flex-direction: row;
        height: 40rem
    }
    .match-detail__tracker-body{
        height:33rem
    }
}
</style>