<template>
  <div class="session-tabs__container" v-if="tabs.length > 0">
    <div
      v-for="(tab, i) in tabs"
      :key="i"
      @click="$emit('changeTab', i, tab.disabled)"
      :class="{ 'selected': tab.selected, 'disabled': tab.disabled }"
      class="session-tabs__tab"
    >
      {{ tab.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style scoped>
.session-tabs__container {
  display: flex;
  justify-content: center;
  align-content: stretch;
  margin: 0.5rem auto;
}
.session-tabs__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-size: 0.875rem;
  font-weight: 700;
  color: #ffffff;
  padding: 0.375rem 0.75rem;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  cursor: pointer;
}
.session-tabs__tab:first-child {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.session-tabs__tab:last-child {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.session-tabs__tab.selected {
  color: #000000;
  background: var(--bg-selected);
  border-color: var(--bg-selected);
}
.session-tabs__tab.disabled {
  color: var(--color-grey-40);
  border: 1px solid var(--color-grey-40);
  cursor: not-allowed;
}
</style>
